import { createAsyncThunk } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getOptions = createAsyncThunk('option/getOptions', async ({ limit, page }, ThunkApi) => {
    try {
        const query = queryString.stringify({ limit, page });
        const response = await apiKey.get(`/options?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getSearchedOptions = createAsyncThunk('option/getSearchedOptions', async ({ searchType, limit, page }, ThunkApi) => {
    try {
        const query = queryString.stringify({ searchType, limit, page });
        const response = await apiKey.get(`/options/filter?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const cudOption = createAsyncThunk('option/cudOption', async ({ params, files }, ThunkApi) => {
    try {
        const formData = new FormData();
        formData.append('options', JSON.stringify([params]));
        if (files) {
            //@ts-ignore
            for (const file of files) {
                formData.append('files[]', file);
            }
        }
        const response = await apiKey.post(`/options/cud-options`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const uploadFileInOption = createAsyncThunk('option/uploadFileInOption', async ({ customId, file }, ThunkApi) => {
    try {
        const formData = new FormData();
        formData.append('id', JSON.stringify(customId));
        if (file) {
            formData.append('files', file);
        }
        const response = await apiKey.post(`/upload-file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteOption = createAsyncThunk('option/deleteOption', async ({ id }, ThunkApi) => {
    try {
        await apiKey.delete(`/options/${id}`);
        return id;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getOptionById = createAsyncThunk('option/getOptionById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/options/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateOption = createAsyncThunk('option/updateOption', async ({ id, data }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/options/${id}`, serialize({ ...data }));
        if (response.status === 200) {
            return response.data;
        }
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
